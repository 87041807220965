
import BorrowerManageModal from "@/components/borrowers/BorrowerManageModal.vue";
import Page from "@/components/core/dashboard/Page.vue";
import dayjs from "@/plugins/day-js";
import { Component, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";

import { format as formatCNPJ } from "@/utils/cnpj";
import { format as formatCPF } from "@/utils/cpf";

import Modal from "@/components/Modal.vue";
import BorrowerStatus from "@/types/borrower-status";
import BorrowerService, {
  BorrowerFilters,
  BorrowerList
} from "../services/borrower-service";
import CompanyService, {
  ListCompaniesResponse
} from "../services/company-service";
import Borrower from "../types/borrower";
import getErrorMessageFromApiError from "../utils/getErrorMessageFromApiError";

@Component({
  components: {
    BorrowerManageModal,
    Modal,
    Page
  }
})
export default class BorrowersManage extends Vue {
  formatCNPJ = formatCNPJ;
  formatCPF = formatCPF;
  options: DataOptions;
  service: BorrowerService;
  companyService: CompanyService;
  companies: ListCompaniesResponse = {
    items: [],
    total: 0
  };
  borrowers: BorrowerList;
  headers: Array<DataTableHeader>;
  addBorrower = false;
  editBorrower?: Borrower | null = null;
  loading = false;
  showDeleteModal: number | null;
  filters: BorrowerFilters = {
    name: "",
    cpf: "",
    statusId: null,
    companyId: null,
    sort: "name:ASC",
    withCompanies: true,
    page: 1,
    limit: 10
  };
  borrowerStatusList: BorrowerStatus[] | null = null;
  loadingXls = false;
  statusColors = (statusId: number): string => {
    switch (statusId) {
      case 1:
        return "success";
      case 2:
        return "error";
      default:
        return "warning";
    }
  };

  constructor() {
    super();
    this.service = BorrowerService.getInstance();
    this.companyService = CompanyService.getInstance();
    this.headers = [
      { text: "Nome Tomador/CPF", value: "borrower", sortable: false },
      {
        text: "Empresas Vinculadas",
        value: "relatedCompanies",
        sortable: false
      },
      { text: "Status", value: "status", sortable: false },
      { text: "Data Cadastrado", value: "createdAt", sortable: false },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.borrowers = {
      data: [],
      total: 0
    };
    this.showDeleteModal = null;
    this.options = {} as DataOptions;
  }

  async created(): Promise<void> {
    this.fetchBorrowerStatusList();
    await this.fetchCompanies();
    await this.fetchBorrowers();
  }

  async fetchBorrowerStatusList(): Promise<void> {
    if (this.hasPermissions(["LISTAR_STATUS_MUTUARIOS"])) {
      const [error, borrowerStatusList] = await this.service.listStatus();
      if (error || !borrowerStatusList) {
        this.$notify({
          type: "error",
          text: "Não foi possível obter a lista de status"
        });
      } else {
        this.borrowerStatusList = borrowerStatusList;
      }
    }
  }

  async fetchCompanies(): Promise<void> {
    const [companyError, companies] = await this.companyService.listCompanies({
      page: 1,
      limit: 999999,
      sort: "name:ASC"
    });

    if (companyError) {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de empresas para o filtro."
      });
    }

    if (companies) {
      this.companies = companies;
    }
  }

  async fetchBorrowers(): Promise<BorrowerList> {
    if (this.hasPermissions(["LISTAR_TOMADORES"])) {
      this.loading = true;
      const [error, borrowers] = await this.service.list(this.filters);

      if (error || !borrowers) {
        this.$notify({
          type: "error",
          text: "Não foi possível obter a lista de tomadores"
        });
      } else {
        this.borrowers = borrowers;
      }
      this.loading = false;
    }
    return this.borrowers;
  }

  async downloadXls(): Promise<void> {
    this.loadingXls = true;
    const [error, data] = await this.service.generateBorrowersReportXls(
      this.filters
    );
    if (!error) {
      window.open(
        process.env.VUE_APP_BORROWER_URL! +
          "/reports/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXls = false;
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }

  applyFilters(): void {
    this.filters.page = 1;
    this.filters.limit = 10;
    this.fetchBorrowers();
  }

  closeModal(): void {
    this.addBorrower = false;
    this.showDeleteModal = null;
    this.editBorrower = null;
    this.$emit("close");
  }

  @Watch("options")
  onOptionsChanged(val: DataOptions) {
    const { page, itemsPerPage } = val;
    this.filters.page = page;
    this.filters.limit = itemsPerPage === -1 ? 0 : itemsPerPage;
    this.fetchBorrowers();
  }

  formatStatus(statusId: number): string | undefined {
    if (this.borrowerStatusList?.length) {
      const statusItem = this.borrowerStatusList.find(
        (item) => item.id === statusId
      );

      if (statusItem) {
        return statusItem.name;
      }
    }
  }

  edit(borrower: Borrower): void {
    this.editBorrower = borrower;
  }
}
